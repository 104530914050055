import React from 'react';

function Header() {
  return (
    <header style={headerStyle}>
      <h1>Meine Website</h1>
      <nav>
        <a href="/" style={linkStyle}>Home</a>
        <a href="/about" style={linkStyle}>About</a>
        <a href="/contact" style={linkStyle}>Contact</a>
      </nav>
    </header>
  );
}

const headerStyle = {
  background: '#333', // Hintergrund auf Dunkelgrau setzen
  color: '#fff',      // Schriftfarbe auf Weiß setzen
  textAlign: 'center',
  padding: '10px'
};

const linkStyle = {
  color: '#fff',       // Links auf Weiß setzen
  textDecoration: 'none',
  margin: '0 15px'
};

export default Header;
