import React, { useState, useEffect } from 'react';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Login from './components/Login';
import Dashboard from './components/Dashboard';

function App() {
  const [token, setToken] = useState(null);  // Zustand für den Login-Token

  // Effekt, um den Token beim Laden der Seite aus dem Local Storage zu holen
  useEffect(() => {
    const savedToken = localStorage.getItem('token');  // Token aus Local Storage holen
    if (savedToken) {
      setToken(savedToken);  // Wenn Token vorhanden, in den State setzen
    }
  }, []);

  // Funktion zum Setzen des Tokens und Speichern im Local Storage
  const handleSetToken = (newToken) => {
    localStorage.setItem('token', newToken);  // Token im Local Storage speichern
    setToken(newToken);  // Token im State setzen
  };

  // Funktion zum Logout und Entfernen des Tokens aus dem Local Storage
  const handleLogout = () => {
    localStorage.removeItem('token');  // Token aus dem Local Storage entfernen
    setToken(null);  // Token im State auf null setzen
  };

  return (
    <div className="App">
      <Header />
      <div className="content">
        {/* Wenn kein Token vorhanden ist, zeige das Login-Formular */}
        {!token ? (
          <div>
            <h2>Bitte logge dich ein</h2>
            <Login setToken={handleSetToken} />
          </div>
        ) : (
          // Zeige das Dashboard, wenn der Benutzer eingeloggt ist
          <Dashboard onLogout={handleLogout} />
        )}
      </div>
      <Footer />
    </div>
  );
}

export default App;
